
import { Component, Vue } from 'vue-property-decorator';
import { post } from '@/shared/requests';

@Component({})
export default class SelectSection extends Vue {
  loading = true;

  error = '';

  enrollments: { course: string; section: string; semester: string; id: string }[] = [];

  journal = {
    id: '',
    name: '',
    dueAt: Date.now() / 1000,
    courses: [],
  };

  mounted() {
    this.fetchSpecifics();
  }

  async fetchSpecifics() {
    const { journalId } = this.$route.params;

    const { success, error, data } = await post('/instructor/journals/specifics', { journalId });

    this.$set(this, 'loading', false);

    if (success && data) {
      this.$set(this, 'enrollments', data.courses);
      this.$set(this, 'journal', data.journal);
    } else if (error) {
      console.error(error);
      this.$set(this, 'error', error.message);
    }
  }
}
